<template>
  <el-drawer title="自定义区域" append-to-body :visible.sync="show" :with-header="false" size="70%">
    <el-tree ref="tree" class="highlight-tree" node-key="_id" :data="data" highlight-current :props="props" :load="loadNode" lazy @node-click="handleNodeClick" :style="{ height: height + 'px', overflow: 'auto' }" />
  </el-drawer>
</template>

<script>
import request from "@/utils/request";

export default {
  props: {
    value: Number | String,
    type: String,
    visible: Boolean,
  },
  data() {
    return {
      data: null,
      show: false,
      height: "",
      props: {
        label: "name",
      },
    };
  },
  watch: {
    visible: function (val) {
      this.show = val;
    },
    show: function (val) {
      this.$emit("update:visible", val);
    },
  },
  mounted() {
    this.height = window.innerHeight;
  },
  methods: {
    loadNode(node, resolve) {
      if (node.data) {
        if (node.data.name == "所有") {
          return resolve([]);
        } else {
          let data = node.data;
          request({
            url: "api/analy/retail/shopTree",
            method: "post",
            data,
          }).then((res) => {
            this.buildNodeKey(res);
            resolve(res || []);
          });
        }
      } else {
        request({
          url: "api/analy/retail/shopTree",
          method: "post",
          data: {},
        }).then((res) => {
          if (res && res.length) {
            this.buildNodeKey(res);
            res.unshift({
              children: null,
              id: "1",
              _id: "1_group",
              isDelete: false,
              name: "所有",
              parentId: null,
              type: "group",
            });
            resolve(res);
            let allChange = res[0];
            setTimeout((_) => {
              this.$refs.tree && this.$refs.tree.setCurrentKey(allChange._id);
              this.handleNodeClick(allChange, true);
            }, 200);
          }
        });
      }
    },
    buildNodeKey(source) {
      (source || []).forEach((o) => {
        o._id = o.type + "_" + o.id;
      });
    },
    handleNodeClick(data, init = false) {
      if (data.id && data.type) {
        this.show = false;
        this.$emit("input", data.id);
        this.$emit("update:type", data.type);
        this.$emit("change", data);
        if (init) {
          this.$emit("init");
        }
      }
    },
  },
};
</script>